import React from 'react';

function Service() {
  return (
    <div>
      <br />
       <b>Program Committees:</b>
      <ul>
	<li>EuroSys'26</li>
	<li>SOSP'25</li>
	<li>HotOS'25</li>
	<li>CIDR'25</li>
	<li>SOSP'24</li>
	<li>OSDI'24</li>
	<li>CIDR'24</li>
	<li>WORDS'23, Co-Chair</li>
	<li>HotOS'23</li>
	<li>EuroSys'23</li>
	<li>OSDI'22</li>
	<li>NSDI'22</li>
	<li>SOSP'21</li>
	<li>ASPLOS'21</li>
	<li>HotOS'21</li>
	<li>EuroDW'21</li>
	<li>OSDI'20</li>
	<li>USENIX ATC'20</li>
	<li>VEE'20</li>
      </ul>
      
       <b>Other Committees:</b>
      <ul>
	<li><a href="https://www.swiss-ai.org/">Swiss AI</a> Scientific Steering Committee</li>
	<li>Dagstuhl Seminar Co-Organizer: Resource Efficient ML Systems, July 2024</li>
	<li>ASPLOS'22 Publication Co-Chair</li>
	<li>EuroSys'22 Needham Dissertation Award Committee</li>
	<li>SOSP'21 Poster Co-Chair</li>
	<li>SoCC'20 Sponsorship Chair</li>
	<li>SOSP'19 Student Research Competition Judge</li>
      </ul>
      <br />
    </div>
  );
}

export default Service;
